#CategoryListCard .MuiTreeItem-root {
    margin-bottom: 5px;
    border-bottom: 1px dotted grey;
}
#CategoryListCard .MuiTreeItem-root .MuiTreeItem-root{
    margin-top: 5px;
}

.category-line {
    width: 100%;
    display: inline-flex;


}
.category-line > .category-line-content{
    flex-grow: 1;
}
